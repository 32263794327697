@import 'antd/dist/antd.less';

.ant-popover-arrow {
  display: none !important;
}
.ant-popover-buttons {
  text-align: center;
  .ant-btn {
    height: 40px;
    padding: 5px 28px;
    font-weight: 600;
  }
}

.ant-layout {
  position: relative;
}

@btn-primary-bg: #F36D32;@btn-default-color: #323234;@btn-border-style: none;@btn-text-color: #323234;@btn-default-bg: #F6F6FA;@btn-border-radius-base: 8px;@btn-disable-bg: rgba(243, 109, 50, 0.5);@btn-disable-color: #FFF;@text-color: #484E5E;@font-family: Inter, sans-serif;@heading-1-size: 25px;@heading-2-size: 18px;@heading-3-size: 15px;@heading-color: #323234;@typography-title-font-weight: 700;@typography-title-margin-top: 0;@typography-title-margin-bottom: 0;@border-radius-base: 8px;@input-height-base: 42px;@input-height-lg: 56px;@input-padding-vertical-base: 9px;@input-padding-horizontal-base: 13px;@input-border-color: #DFDFE3;@input-color: #323234;@input-placeholder-color: #040D6147;@input-disabled-bg: #FCFCFF;@input-hover-border-color: #F36D32;@radio-size: 14px;@radio-dot-color: #F36D32;@radio-button-bg: #FFF;@radio-button-checked-bg: #FFF;@radio-button-color: #FFF;@radio-button-active-color: #F36D32;@checkbox-size: 16px;@checkbox-color: #F36D32;@checkbox-check-color: #FFF;@checkbox-check-bg: #FFF;@checkbox-border-width: 1px;@checkbox-border-radius: 2px;@checkbox-group-item-margin-right: 8px;@select-border-color: #DFDFE3;@select-item-selected-color: #FFF;@select-item-selected-bg: #F36D32;@select-item-active-bg: #FFF3EE;@select-background: #FFF;@layout-sider-background: #F6F6FA;@layout-sider-background-light: #F6F6FA;@layout-body-background: #FCFCFF;@menu-bg: #F6F6FA;@menu-item-height: 56px;@menu-item-color: #323234;@menu-item-active-bg: #F0F0F4;@menu-item-group-height: 56px;@popover-arrow-width: 0;@table-header-bg: #FFF;@table-header-color: #040D6147;@table-row-hover-bg: #fff3ee66;@text-color: #323234;@label-required-color: #8E8FA1;@label-color: #8E8FA1;@modal-body-padding: 32px;@breadcrumb-link-color: #8E8FA1;@breadcrumb-separator-color: #8E8FA1;@breadcrumb-font-size: 16px;@breadcrumb-separator-margin: 0 13px;@switch-color: #F36D32;